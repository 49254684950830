/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif !important;
}

.button-container {
  display: flex;

  & button:not(:last-child) {
    margin-right: 1rem;
  }
}

.mat-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.box-confirm-button {
  background-color: rgba(22, 114, 236, 1);
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.75rem 0;
  border: none;
  color: #C5DCFA;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.8px;
}

.box-decline-button {
  background-color: rgba(245, 65, 61, 1);
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.75rem 0;
  border: none;
  color: #C5DCFA;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.8px;
}

.link {
  text-decoration: none;
  cursor: pointer;
}
